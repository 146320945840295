<template>
  <div class="px-5" id="page-history">
    <b-card class="px-0">

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('HISTORY.USERS') }}</v-tab>
        <v-tab>{{ $t('HISTORY.MEMBERS') }}</v-tab>
        <v-tab>{{ $t('HISTORY.SETTINGS') }}</v-tab>

        <v-tab-item>

          <HistoryEditor
            class="mt-4"
            ref="history_editor"
            group="USER"
            v-if="selected_history"
            :history="selected_history"
          />

          <HistoryTable 
            class="mt-4"
            group="USER"
          />

        </v-tab-item>
        
        <v-tab-item>
          
          <HistoryEditor
            class="mt-4"
            ref="history_editor"
            group="MEMBER"
            v-if="selected_history"
            :history="selected_history"
          />

          <HistoryTable 
            class="mt-4"
            group="MEMBER"
          />

          
        </v-tab-item>

        <v-tab-item>

          <HistorySettings />

        </v-tab-item>

      </v-tabs>
    </b-card>
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

// Edit component
import HistoryTable from './history/HistoryTable.vue';
import HistoryEditor from './history/HistoryEditor.vue';
import HistorySettings from './history/HistorySettings.vue';

export default {
  name: 'history-page',
  mixins: [ toasts ],
  components: {
    HistoryTable,
    HistoryEditor,
    HistorySettings
},
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    //this.example = this.examples[0];
  },
  data() {
    return {
      selected_history: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

  }
};
</script>
